<template>
  <div class="max-w-screen-xl mx-auto px-4 py-20">
    <h3 class="text-center text-5xl font-bold mb-10">all clint</h3>
    <data class="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2 gap-5">
      <div
        class="p-6 shadow-lg mb-5 border flex flex-col justify-center items-center"
        v-for="client in clintList?.data"
        :key="client.id"
      >
        <img
          :src="`${store.state.imageUrl}${client?.logo}`"
          @error="handleImageError"
          class="max-w-[133.6px] w-full mb-6"
        />
        <router-link
          :to="`/${client?.domain_name}`"
          class="text-blue-600 text-xl font-semibold text-center"
        >
          {{ client?.company_name }}
        </router-link>
      </div>
    </data>
  </div>
</template>
<script setup>
  import { computed, onMounted, defineComponent } from "vue";
  import { useStore } from "vuex";
  import { useI18n } from "vue-i18n";
  const store = useStore();
  defineComponent({
    name: "HomePage",
  });
  const { locale } = useI18n();
  const clintList = computed(() => store.getters.allClientData);

  async function fetchData() {
    const headers = {
      "Accept-Language": locale,
    };
    await store.dispatch("fetchAllClientData", {
      endpoint: "clients",
      headers,
    });
  }

  onMounted(() => {
    fetchData();
  });
</script>
