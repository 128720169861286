import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
// import MenuView from "../views/MenuView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:domain_name", // Define dynamic segment
    name: "menu", // Specify a unique name for the route
    // component: MenuView,
    component: () => import("../views/MenuView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
