import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createI18n } from 'vue-i18n'
import store from './store'
import './index.css'

// Import the fontawesome core
import { library } from '@fortawesome/fontawesome-svg-core'
// Import font awesome icon component
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import specific icons
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

// Add icons to the library
library.add(fas, fab)

// Import locale messages
import en from './locales/en.json'
import ar from './locales/ar.json'

// Create i18n instance with options
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en,
    ar
  }
})

const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(store)
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')
