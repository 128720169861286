// src/store/index.js

import { createStore } from "vuex";
import axiosInstance from "../plugins/axios";

const store = createStore({
  state() {
    return {
      apiUrl: "https://trengle-glo.com/tr-menu/api",
      imageUrl: "https://trengle-glo.com/tr-menu/public/",
      allClientData: null,
      clientData: null,
      menuItems: null,
      branchesList: null,
      clientSliders: null,
      deliveryList: null,
    };
  },
  mutations: {
    setAllClientData(state, data) {
      state.allClientData = data;
    },
    setClientData(state, data) {
      state.clientData = data;
    },
    setMenuItems(state, data) {
      state.menuItems = data;
    },
    setBranchesList(state, data) {
      state.branchesList = data;
    },
    setClientSliders(state, data) {
      state.clientSliders = data;
    },
    setDeliveryList(state, data) {
      state.deliveryList = data;
    },
  },
  actions: {
    async fetchAllClientData({ commit }, { endpoint, headers, params }) {
      try {
        const response = await axiosInstance.get(endpoint, {
          headers,
          params,
        });
        commit("setAllClientData", response.data);
      } catch (error) {
        console.error("Error fetching all client data:", error);
      }
    },
    async fetchClientData({ commit }, { endpoint, headers, params }) {
      try {
        const response = await axiosInstance.get(endpoint, {
          headers,
          params,
        });
        commit("setClientData", response.data);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    },
    async fetchMenuItems({ commit }, { endpoint, headers, params }) {
      try {
        const response = await axiosInstance.get(endpoint, {
          headers,
          params,
        });
        commit("setMenuItems", response.data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    },
    async fetchBranchesList({ commit }, { endpoint, headers, params }) {
      try {
        const response = await axiosInstance.get(endpoint, {
          headers,
          params,
        });
        commit("setBranchesList", response.data);
      } catch (error) {
        console.error("Error fetching branches list:", error);
      }
    },
    async fetchClientSliders({ commit }, { endpoint, headers, params }) {
      try {
        const response = await axiosInstance.get(endpoint, {
          headers,
          params,
        });
        commit("setClientSliders", response.data);
      } catch (error) {
        console.error("Error fetching client sliders:", error);
      }
    },
    async fetchDeliveryList({ commit }, { endpoint, headers, params }) {
      try {
        const response = await axiosInstance.get(endpoint, {
          headers,
          params,
        });
        commit("setDeliveryList", response.data);
      } catch (error) {
        console.error("Error fetching delivery list:", error);
      }
    },
  },
  getters: {
    allClientData(state) {
      return state.allClientData;
    },
    clientData(state) {
      return state.clientData;
    },
    menuItems(state) {
      return state.menuItems;
    },
    branchesList(state) {
      return state.branchesList;
    },
    clientSliders(state) {
      return state.clientSliders;
    },
    deliveryList(state) {
      return state.deliveryList;
    },
  },
});

export default store;
